import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerGeograph from '../components/BannerGeograph'

import kentei06 from '../assets/images/kentei06.png'


const Geograph = (props) => (
    <Layout>
        <Helmet>
            <title>都市構造可視化推進機構について</title>
            <meta name="description" content="都市構造可視化計画ウェブサイトの概要" />
        </Helmet>

        <BannerGeograph />

        <div id="main">
            
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>ジオグラフ＆都市構造可視化</h2>
                    </header>
                    <p>　帝国書院と都市構造可視化推進機構は「ジオグラフ」を開発し、運営しています。</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src="/top_l5.png" alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>「都市構造可視化」を知る</h3>
                            </header>
                            <p>
                            ・動画を見る<br/>
                            　- <a href="https://youtu.be/G9GpggxoT60" target="_blank" rel="noopener noreferrer">ワークショップでの活用風景 (3min)</a><br/>
                            　- <a href="https://mieruka.city/members/view" target="_blank" rel="noopener noreferrer">有識者による可視化の紹介動画 (10min×10)</a><br/>
                            　- <a href="https://youtu.be/wWVBsedDmG4" target="_blank" rel="noopener noreferrer">まちづくりのために都市を見える化しよう (50min)</a><br/>
                            <br/>
                            ・論文を見る<br/>
                            　- <a href="https://www.cpij.or.jp/com/ac/reports/20_146.pdf" target="_blank" rel="noopener noreferrer">ジオグラフができるまで<span style={{fontSize:"xx-small"}}> (高校地理教育における都市構造可視化の活用に関する研究)</span></a><br/>
                            {/* 　- <a href="https://www.jstage.jst.go.jp/article/journalcpij/45.3/0/45.3_169/_pdf/-char/ja" target="_blank" rel="noopener noreferrer">2010 可視化のはじまり</a><br/> */}
                            <br/>
                            ・使い方を見る＆使ってみる<br/>
                            　- <a href="https://mieruka.city/movies/structure" target="_blank" rel="noopener noreferrer">都市構造可視化計画の使い方</a><br/>
                            　- <a href="https://mieruka.city/" target="_blank" rel="noopener noreferrer">都市構造可視化計画ウェブサイト</a><br/>
                            <br/>
                            ・（参考）都市計画での活用事例を見る<br/>
                            　- <a href="https://minna.mieruka.city/" target="_blank" rel="noopener noreferrer">都市構造可視化計画 活用アイデア集</a><br/>
                            　- <a href="https://meet2020.kashika.or.jp/" target="_blank" rel="noopener noreferrer">i-都市交流会議2020イベントサイト (英語)</a><br/>
                            　- <a href="https://sns.kashika.or.jp/public/i_toshi2020_1/" target="_blank" rel="noopener noreferrer">i-都市交流会議2020活動報告 (128自治体の発表事例等)</a><br/>
                            </p>

                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/kentei" className="image">
                        <img src={kentei06} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>「都市構造可視化」をもっと活用する</h3>
                            </header>
                            <p>
                            ・都市計画学会表彰制度<br/>
                            　- 学生、生徒の皆さんの可視化を使った分析事例を募集しています。 →<a href="https://mieruka.city/features/view/10" target="_blank" rel="noopener noreferrer">詳しくはこちら</a><br/>
                            <br/>
                            ・都市計画ビジュアルコミュニケーター検定<br/>
                            　- 可視化の活用能力を証明します。どなたでも受験できます。テキスト、問題集もこちらから。 →<a href="/kentei">詳しくはこちら</a><br/>
                            <br/>
                            ・可視化研修<br/>
                            　- 可視化の活用についての研修サービスを行なっています。可視化に必要な「PC、3Dマウス、wifiルータ」の貸与により、どなたでも気軽に可視化を始めることができます。 →<a href="/kensyu">詳しくはこちら</a><br/>
                            <div style={{margin:"0.5%"}}>
                                    <iframe width="100%" src="https://www.youtube.com/embed/G9GpggxoT60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                            </div>
                            <br/>
                            ・交流会<br/>
                            　- 表彰者、検定合格者、研修受講者等による、可視化の活用についての交流会を行なっています。（参加希望の方は、それぞれの申し込みの際の連絡先までお問い合わせください。会員による推薦制度もあります。）<br/>
                            </p>

                            <ul className="actions">
                                <li><Link to="/" className="button">都市構造可視化推進機構トップ</Link></li>
                            </ul>

                        </div>
                    </div>
                </section>

            </section>
        </div>

    </Layout>
)

export default Geograph
import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style1">
        <div className="inner">
            <header className="major">
                <h1>ジオグラフをもっと活用しよう！</h1>
            </header>
            <div className="content">
                <p>都市構造可視化推進機構は、プロボノ活動により運営されている非営利型の一般社団法人です。<br/>
　※プロボノ活動とは「専門家としての技能を活かした社会貢献活動」のことです。
</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
